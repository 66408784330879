<template>
  <div>
    <v-container>
      <page-title title="Booking Material">
        <template slot="action">
          <add-dialog v-if="modulePermission('bookingmaterial', 'create')"></add-dialog>
        </template>
      </page-title>

      <v-card class="mb-2">
        <list-data></list-data>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import AddDialog from './components/AddDialog.vue';
import ListData from "./components/ListData.vue";
export default {
  components: {
    ListData,
    AddDialog,
  },

  mounted() {
    this.requiredLogin();

    this.modulePermission("bookingmaterial", "view", true);
  },
};
</script>
