<template>
  <div>
    <v-btn color="primary" v-if="modulePermission('bookingmaterial', 'create')" @click="add()">Add</v-btn>

    <v-dialog v-model="dialog" max-width="600">
      <v-form v-model="valid" ref="refFormCreateDocument" lazy-validation :loading="loading" :disabled="loading">
        <v-card :loading="loading">
          <v-card-title>Create Document</v-card-title>
          <v-divider class="mb-4"></v-divider>
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field outlined v-model="kom_no_" :rules="kom_no_Rules" label="KOM No."></v-text-field>
                <v-text-field outlined v-model="kom_date" :rules="kom_date_Rules" label="KOM Date" type="date"></v-text-field>
                <v-text-field outlined v-model="kom_status" :rules="kom_status_Rules" label="KOM Status" type="number"></v-text-field>
                <v-autocomplete outlined v-model="selected_quotes" :loading="loading_quotes" :rules="selected_quotes_Rules" label="Quotes" :items="quotes" item-text="quote_no_" item-value="quote_no_" multiple></v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn class="primary" :loading="loading" @click="createDocument()" :disabled="!valid">Create Document</v-btn>
            <v-spacer></v-spacer>
            <v-btn @click.stop="dialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      search: "",
      tab: null,

      dialog: null,

      valid: false,
      loading: null,
      customer_no_: null,
      campaign_no_: null,
      kom_no_: null,
      kom_date: null,
      kom_status: null,
      selected_quotes_Rules: [(v) => !!v || "Area Code is required"],
      kom_no_Rules: [(v) => !!v || "KOM No. is required"],
      kom_date_Rules: [(v) => !!v || "KOM Date is required"],
      kom_status_Rules: [(v) => !!v || "KOM Status is required"],
      disabledBtnCreateDocument: true,
      selected_quotes: [],

      quotes: [],
      loading_quotes: false,
    };
  },

  watch: {
    dialog(visible) {
      if (visible) {
        if (typeof this.$refs.refFormCreateDocument !== "undefined") {
          this.$refs.refFormCreateDocument.reset();
        }
      } else {
        console.log("Dialog was closed!");
      }
    },
    // eslint-disable-next-line no-unused-vars
    customer_no_(n) {
      this.getCampaignList(true);
    },
    // eslint-disable-next-line no-unused-vars
    campaign_no_(n) {
      this.getArea(true);
    },
    // eslint-disable-next-line no-unused-vars
    area_code(n) {
      this.getQuotes(true);
    },

    // eslint-disable-next-line no-unused-vars
    selected_quotes(n, o){
      if(n.length > 4) {
        var i = this.selected_quotes.indexOf(n);

        this.selected_quotes.splice(i);

        this.showAlert("error", "Max 4 Quotes");
      }
    }
  },

  computed: {},

  methods: {
    add() {
      if(!this.modulePermission('bookingmaterial', 'create')) return;

      this.kom_no_ = "";
      this.kom_date = "";
      this.kom_status = 1;
      this.selected_quotes = [];
      this.dialog = true;
      this.valid = false;
    },
    async createDocument() {
      if(!this.modulePermission('bookingmaterial', 'create')) return;

      if (!this.valid) {
        this.showAlert("error", "Form Invalid!");
        return;
      }

      this.loading = true;
      var formData = new FormData();
      formData.append("kom_no_", this.kom_no_);
      formData.append("kom_date", this.kom_date);
      formData.append("kom_status", this.kom_status);
      formData.append("quotes", this.selected_quotes);
      await this.$axios
        .post("bookingmaterial/create-document", formData)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.redirect("Warehouse.BookingMaterial.Edit", { id: resData.data.id });
          }
          this.loading = false;
          this.showLoadingOverlay(false);
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.loading = false;
          this.showLoadingOverlay(false);
        });
    },
    async getQuotes(refresh=false) {
      this.quotes = [];
      var config = {
        cacheConfig: refresh,
        // params: params,
      };

      this.loading_quotes = true;
      await this.$axios
        .get("bookingmaterial/quotes", config)
        .then((res) => {
          var resData = res.data;

          this.quotes = resData.data.sales_quotes;
          // this.sales_quote_lines = resData.data.sales_quote_lines;

          this.loading_quotes = false;
        })
        .catch((error) => {
          this.loading_quotes = false;
          this.axiosErrorHandler(error);
        });
    },
  },

  mounted() {
    this.modulePermission("bookingmaterial", "view", true);
    this.getQuotes(true);
  },
};
</script>
