<template>
  <div>
    <v-card flat elevation="0">
      <v-toolbar dense elevation="0">
        <v-toolbar-title>Booking Material</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-text-field v-if="!isMobile" dense autofocus outlined rounded placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 300px" hide-details single-line></v-text-field>

        <v-btn icon @click="getData(true)" title="Refresh">
          <v-icon>mdi-sync</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="px-2" v-if="isMobile">
        <v-text-field class="mb-2" dense clearable autofocus outlined placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 3000px" hide-details single-line></v-text-field>
      </div>
      <v-data-table :options.sync="datatable_options" :loading="datatable.loading" :loading-text="datatable.loadingText" :headers="datatable.columns" :items="datatable.data" :search="search" :hide-default-header="isMobile" :server-items-length="datatable.totalRows" dense class="mb-3 my-0">
        <template v-slot:[`item.document_no_`]="{ item }" v-if="!isMobile">
          <a href="javascript:;" @click="getDetail(item.id)">{{ item.document_no_ }}</a>
        </template>
        <template v-slot:[`item`]="{ item }" v-if="isMobile">
          <v-card elevation="4" class="ma-1" outlined>
            <v-card-title class="text-h5 mb-1 pb-1" @click="getDetail(item.id)">{{ item.document_no_ }}</v-card-title>
            <v-card-subtitle class="my-0 py-0 mb-3">
              {{ item.kom_no_ }} <br />
              {{ item.kom_status }}
            </v-card-subtitle>
          </v-card>
        </template>
        <v-alert slot="no-results" :value="true" color="error" icon="warning">Your search for "{{ search }}" found no results.</v-alert>s
      </v-data-table>
    </v-card>

    <v-navigation-drawer v-model="rightbar" fixed bottom temporary right width="350" class="pt-12">
      <v-toolbar elevation="0" dense absolute width="100%" fullscreen>
        <v-toolbar-title>{{ detail.header.document_no_ }}</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon text v-bind="attrs" v-on="on" @click="redirect('Warehouse.BookingMaterial.Edit', { id: detail.header.id })" title="Edit">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>

        <!-- <v-btn v-if="detail.header.status == 'open'" icon text class="" color="primary" @click="setStatus(detail.header.id, 'released')" :loading="loading_delete" title="Released">
          <v-icon>mdi-airplane</v-icon>
        </v-btn> -->
        <!-- <v-btn v-if="detail.header.status == 'released'" icon text class="" color="primary" @click="setStatus(detail.header.id, 'open')" :loading="loading_delete" title="Re-Open">
          <v-icon>mdi-refresh</v-icon>
        </v-btn> -->
        <!-- <v-btn icon text class="" color="primary" @click="deleteData(detail.header.id)" :loading="loading_delete" title="Delete">
          <v-icon>mdi-delete</v-icon>
        </v-btn> -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <log-data :id="detail.header.id">
              <v-btn icon text color="light" v-bind="attrs" v-on="on" small>
                <v-icon>mdi-clipboard-text-clock</v-icon>
              </v-btn>
            </log-data>
          </template>
          <span>Document Log</span>
        </v-tooltip>

        <!-- <v-btn icon text small @click.stop="rightbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn> -->

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on" icon text small>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense min-width="160px" right>
            <v-list-item @click="redirect('Warehouse.BookingMaterial.Edit', { id: detail.header.id })">
              <!-- <v-list-item-icon>
                <v-icon>mdi-pencil</v-icon>
              </v-list-item-icon> -->
              <v-list-item-content>
                <v-list-item-title>Edit</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="detail.header.status == 'open'" @click="setStatus(detail.header.id, 'released')">
              <!-- <v-list-item-icon>
                <v-icon>mdi-airplane</v-icon>
              </v-list-item-icon> -->
              <v-list-item-content>
                <v-list-item-title>Release</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="detail.header.status == 'released'" @click="setStatus(detail.header.id, 'open')">
              <!-- <v-list-item-icon>
                <v-icon>mdi-refresh</v-icon>
              </v-list-item-icon> -->
              <v-list-item-content>
                <v-list-item-title>Re-Open</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="detail.header.status == 'open'" @click="deleteData(detail.header.id)">
              <!-- <v-list-item-icon>
                <v-icon>mdi-delete</v-icon>
              </v-list-item-icon> -->
              <v-list-item-content>
                <v-list-item-title>Delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="deleteData(detail.header.id)">
              <!-- <v-list-item-icon>
                <v-icon>mdi-delete</v-icon>
              </v-list-item-icon> -->
              <v-list-item-content>
                <v-list-item-title>Delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click.stop="rightbar = false">
              <!-- <v-list-item-icon>
                <v-icon>mdi-close</v-icon>
              </v-list-item-icon> -->
              <v-list-item-content>
                <v-list-item-title>Close</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
      <v-divider></v-divider>
      <div class="px-2 py-2">
        <div class="text-center mx-auto my-4" v-if="loading">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
        <v-row>
          <v-col cols="12">
            <v-text-field dense readonly v-model="detail.header.document_no_" placeholder="Doc No." label="Doc No." outlined hide-details class="mb-2"></v-text-field>
            <v-text-field dense readonly v-model="detail.header.document_date" placeholder="Doc. Date" label="Doc. Date" outlined hide-details class="mb-2" type="date"></v-text-field>
            <v-text-field dense readonly v-model="detail.header.kom_no_" placeholder="KOM No." label="KOM No." outlined hide-details class="mb-2"></v-text-field>
            <v-text-field dense readonly v-model="detail.header.kom_date" placeholder="KOM Date" label="KOM Date" outlined hide-details class="mb-2" type="date"></v-text-field>
            <v-text-field dense readonly v-model="detail.header.kom_status" placeholder="KOM Status" label="KOM Status" outlined hide-details class="mb-2"></v-text-field>
            <v-text-field dense readonly v-model="detail.header.area_code" placeholder="Area Code" label="Area Code" outlined hide-details class="mb-2"></v-text-field>
            <v-text-field dense readonly v-model="detail.header.customer_no_" placeholder="Customer No." label="Customer No." outlined hide-details class="mb-2"></v-text-field>
            <v-text-field dense readonly v-model="detail.header.campaign_no_" placeholder="Campaign No." label="Campaign No." outlined hide-details class="mb-2"></v-text-field>
            <v-text-field dense readonly v-model="detail.header.status" placeholder="Status" label="Status" outlined hide-details class="mb-2"></v-text-field>
            <v-text-field dense readonly v-model="detail.header.created_by_username" placeholder="Created By" label="Created By" outlined hide-details class="mb-2"></v-text-field>
            <v-text-field dense readonly v-model="detail.header.created_at" placeholder="Created At" label="Created At" outlined hide-details class="mb-2" type="date"></v-text-field>

            <v-sheet elevation="1" outlined rounded="lg">
              <v-sheet class="py-1 px-2 primary" dark rounded="t-lg">
                Quotes
              </v-sheet>

              <div class="pa-1">
                <v-chip-group column>
                  <v-chip v-for="q in detail.quotes" :key="q.id">
                    {{ q.quote_no_ }}
                  </v-chip>
                </v-chip-group>
              </div>
            </v-sheet>
          </v-col>
        </v-row>
      </div>
      <!-- <v-toolbar elevation="0" dense absolute width="100%" top class="mt-8" min-height="0px">
        <v-btn icon text color="primary" @click="redirect('Warehouse.BookingMaterial.Edit', { id: detail.header.id })" title="Edit">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn v-if="detail.header.status == 'open'" icon text class="" color="primary" @click="setStatus(detail.header.id, 'released')" :loading="loading_delete" title="Released">
          <v-icon>mdi-airplane</v-icon>
        </v-btn>
        <v-btn v-if="detail.header.status == 'released'" icon text class="" color="primary" @click="setStatus(detail.header.id, 'open')" :loading="loading_delete" title="Re-Open">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-btn icon text class="" color="primary" @click="deleteData(detail.header.id)" :loading="loading_delete" title="Delete">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <log-data :id="detail.header.id">
              <v-btn icon text color="primary" v-bind="attrs" v-on="on">
                <v-icon>mdi-clipboard-text-clock</v-icon>
              </v-btn>
            </log-data>
          </template>
          <span>Document Log</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-btn icon text @click.stop="rightbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar> -->
    </v-navigation-drawer>
  </div>
</template>

<script>
import LogData from "./LogData.vue";
export default {
  components: {
    LogData,
  },
  data() {
    return {
      elevations: [6, 12, 18],
      loading: null,
      search: "",
      datatable_options: {},
      datatable: {
        options: {},
        loading: null,
        loadingText: "Loading... Please wait...",
        totalRows: 0,
        columns: [
          { text: "Document No.", value: "document_no_" },
          { text: "Document Date", value: "document_date" },
          { text: "Customer No.", value: "customer_no_" },
          { text: "Campaign No.", value: "campaign_no_" },
          { text: "KOM No.", value: "kom_no_" },
          { text: "KOM Date", value: "kom_date" },
          { text: "KOM Status", value: "kom_status" },
          { text: "Status", value: "status" },
          { text: "Created By", value: "created_by_username" },
          { text: "Created At", value: "created_at" },
        ],
        data: [],
      },

      dialog: null,
      detail: {
        header: {},
        quotes: [],
        items: [],
      },
      rightbar: null,

      loading_delete: false,
    };
  },
  watch: {
    datatable_options: {
      handler() {
        this.getData();
      },
      deep: true,
    },

    search: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
  methods: {
    async getData(refresh) {
      if (refresh) this.AxiosStorageRemove("POST", "bookingmaterial/data");

      const { sortBy, sortDesc, page, itemsPerPage } = this.datatable_options;

      this.datatable.loading = true;
      var formData = new FormData();
      formData.append("search", this.search);
      formData.append("sort_by", sortBy);
      formData.append("sort_desc", sortDesc);
      formData.append("page", page);
      formData.append("limit", itemsPerPage);
      await this.$axios
        .post("bookingmaterial/data", formData)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.data = resData.data.results;
            this.datatable.totalRows = resData.data.paging.total_rows;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.datatable.loading = false;
        });
    },
    async getDetail(id) {
      var config = {
        params: {
          id: id,
        },
      };
      this.showLoadingOverlay(true);
      this.rightbar = false;
      await this.$axios
        .get("bookingmaterial/detail", config)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.detail.header = resData.data.header;
            this.detail.quotes = resData.data.quotes;
            this.detail.items = resData.data.items;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.rightbar = true;

          this.showLoadingOverlay(false);
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
          this.rightbar = false;
        });
    },
    async deleteData(id) {
      const post = async () => {
        this.showLoadingOverlay(true);
        this.loading_delete = true;
        var formData = new FormData();
        formData.append("id", id);
        await this.$axios
          .post("bookingmaterial/delete", formData)
          .then((res) => {
            var resData = res.data;
            this.showAlert(resData.status, resData.message);
            this.showLoadingOverlay(false);
            this.loading_delete = false;
            this.getData();
          })
          .catch((error) => {
            this.showLoadingOverlay(false);
            this.loading_delete = false;
            this.axiosErrorHandler(error);
          });
      };
      this.showConfirm("Confirm", "Delete Booking Material", post);
    },
    async setStatus(id, status) {
      const post = async () => {
        this.showLoadingOverlay(true);
        this.loading_status = true;
        var formData = new FormData();
        formData.append("id", id);
        formData.append("status", status);
        await this.$axios
          .post("bookingmaterial/set-status", formData)
          .then((res) => {
            var resData = res.data;
            this.showAlert(resData.status, resData.message);
            this.showLoadingOverlay(false);
            this.loading_status = false;
            this.getData();
          })
          .catch((error) => {
            this.showLoadingOverlay(false);
            this.loading_status = false;
            this.axiosErrorHandler(error);
          });
      };
      var msg = "";
      if (status == "released") {
        msg = "Release Document?";
      } else if (status == "open") {
        msg = "Re-Open Document?";
      } else {
        return;
      }
      this.showConfirm("Confirm", msg, post);
    },
  },

  mounted() {
    this.getData();
  },
};
</script>
