<template>
  <div style="display: inline-block;">
    <div href="javascript:;" @click="showDialog()">
      <slot></slot>
    </div>
    <v-dialog v-model="dialog" class="dialogdetail" max-width="600">
      <v-card :loading="loading">
        <v-card-title class="text-h5 grey lighten-2">
          Document Log
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="ms-2" title="Refresh" v-bind="attrs" v-on="on" @click="refreshData()">mdi-refresh</v-icon>
            </template>
            <span>Refresh</span>
          </v-tooltip>

          <v-btn color="light" @click="dialog = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="py-4" style="max-height: 550px;overflow-y: auto;">
          <v-row>
            <v-col cols="12">
              <div class="d-flex">
                <v-spacer></v-spacer>
                <v-text-field dense autofocus outlined rounded placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 300px" hide-details single-line></v-text-field>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-sheet elevation="1" style="min-height: 250px;">
          <v-data-table :options.sync="datatable_options" :loading="datatable.loading" :loading-text="datatable.loadingText" :headers="datatable.columns" :items="datatable.data" :search="search" :hide-default-header="isMobile" :server-items-length="datatable.totalRows" :items-per-page="datatable_options.itemsPerPage" dense class="mb-3 my-0" hide-default-footer @page-count="pageCount = $event"></v-data-table>
        </v-sheet>

        <v-divider></v-divider>

        <v-card-actions>
          <v-pagination v-model="datatable_options.page" :length="totalPage"></v-pagination>
          <v-spacer></v-spacer>
          <v-btn color="light" @click="dialog = false" small> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["id", "callback"],
  data() {
    return {
      dialog: null,
      loading: null,
      search: "",
      datatable_options: {},
      itemsPerPageOption: [10, 20, 50, 100, 500],
      totalPage: 0,
      datatable: {
        loading: null,
        loadingText: "Loading... Please wait...",
        totalRows: 0,
        columns: [
          { text: "Date Time", value: "log_datetime" },
          { text: "Username", value: "username" },
          { text: "Activity", value: "log_activity" },
          { text: "Description", value: "log_description" },
        ],
        data: [],
      },
    };
  },
  watch: {
    dialog(n, o) {
      if ((o == true) && (n == false)) {
        if (typeof this.callback === 'function') {
          this.callback()
        }
      }

      if ((o == false) && (n == true)) {
        this.getData(true);
      }
    },
    datatable_options: {
      handler() {
        this.getData();
      },
      deep: true,
    },

    search: {
      handler() {
        this.datatable_options.page = 1;
        this.getData();
      },
      deep: true,
    },
  },
  methods: {
    showDialog() {
      this.dialog = true;
    },
    refreshData() {
      this.getData(true);
    },
    getData(refresh) {
      if (refresh) this.AxiosStorageRemove("POST", "bookingmaterial/get-log");

      const { sortBy, sortDesc, page, itemsPerPage } = this.datatable_options;

      this.datatable.loading = true;
      var formData = new FormData();
      formData.append("search", this.search);
      formData.append("sort_by", sortBy);
      formData.append("sort_desc", sortDesc);
      formData.append("page", page);
      formData.append("limit", itemsPerPage);

      formData.append("id", this.id);

      this.$axios
        .post("bookingmaterial/get-log", formData)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.data = resData.data.results;
            this.datatable.totalRows = resData.data.paging.total_rows;
            this.totalPage = resData.data.paging.total_page;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.datatable.loading = false;
        });
    },
  },
};
</script>
